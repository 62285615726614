<template>
  <div class="campanha">
    <a class="b-col-sm-1">
      <i v-if="campanha.status === 0" class="fa fa-fw fa-user-edit text-blue-grey-2 text-3x m-t-xs"
         title="Rascunho"></i>
      <i v-if="campanha.status === 1" class="fa fa-fw fa-spin fa-spinner text-warning text-3x m-t-xs"
         title="Preparando"></i>
      <i v-if="campanha.status === 2" class="fa fa-fw fa-chess-clock text-primary text-3x m-t-xs"
         title="Pronto para envio"></i>
      <i v-if="campanha.status === 3" class="fa fa-fw fa-spin fa-spinner text-green text-3x m-t-xs"
         title="Enviando"></i>
      <i v-if="campanha.status === 98" class="fa fa-fw fa-pause text-purple text-3x m-t-xs" title="Pausado"></i>
      <i v-if="campanha.status === 99" class="fa fa-fw fa-times text-negative text-3x m-t-xs" title="Cancelado"></i>
      <i v-if="campanha.status === 100" class="fa fa-fw fa-check-circle text-success text-3x m-t-xs"
         title="Enviado"></i>
    </a>
    <div class="b-col-sm-5">
      <div>
        <div class="m-b-xs">
          <span style="padding: 1px 4px; border-radius: 4px; background-color: #f2f2f2; font-size: 10px; color: #000000">{{ campanha.canal }}</span>
          <span style="padding: 1px 4px; border-radius: 4px; background-color: #f2f2f2; font-size: 10px; color: #000000; margin-left: 5px">ID {{ campanha.id }}</span>
        </div>
        <campanha-titulo :titulo="campanha.nome"/>
        <div class="m-t-sm">
          {{ getTipoLabel(campanha.tipoString) }}
        </div>
      </div>
      <div class="text-ellipsis m-t-sm">
        <span><strong>{{ campanha.statusString }}.</strong> <span
            v-if="campanha.dataInicio">Iniciada {{ campanha.dataInicio.date|formatDateDistance }}</span><span
            v-if="campanha.dataTermino">Concluída {{ campanha.dataTermino.date|formatDateDistance }}</span></span>
      </div>
    </div>
    <div class="b-col-sm-4 no-padder m-t hoverable">
      <div class="b-col-md-3">
        <div class="">{{ campanha.cacheInscritos|number }}</div>
        <div class="text-muted inline m-t-xs">inscritos</div>
        <hr class="clearfix hidden-sm hidden-md hidden-lg m-t-xs m-b-xs">
      </div>
      <div class="b-col-md-3">
        <div class="">{{ campanha.cacheEnviados|number }}</div>
        <div class="text-muted inline m-t-xs">envios</div>
        <u-progress class="progress-sgrp m-r"
                    :percentage="Number(progressoCampanha)"
                    color="positive" height="6px" style="border-radius: 5px; max-width: 100%"></u-progress>
        <hr class="clearfix hidden-sm hidden-md hidden-lg m-t-xs m-b-xs">
      </div>
      <div class="b-col-md-3">
        <div class="hide-on-hover">{{ campanha.cacheAbertos|number }}</div>
        <div class="show-on-hover">{{ campanha.cacheAbertos|number }}</div>
        <div class="text-muted inline m-t-xs">abertos</div>
        <hr class="clearfix hidden-sm hidden-md hidden-lg m-t-xs m-b-xs">
      </div>
      <div class="b-col-md-3 hoverable">
        <div class="hide-on-hover">{{ campanha.cacheCliques|number }}</div>
        <div class="show-on-hover">{{ campanha.cacheCliques|number }}</div>
        <div class="text-muted m-t-xs">cliques</div>
        <hr class="clearfix hidden-sm hidden-md hidden-lg m-t-xs m-b-xs">
      </div>
    </div>
    <div class="b-col-sm-2">
      <div class="u-btn-group-container m-t text-right">
        <u-btn @click="$router.push({name: 'marketing.campanha', params: {id: campanha.id}})" label="Visualizar" no-caps
               color="grey-3" text-color="blue-grey-8"/>
        <!--              <u-btn icon="caret-down" icon-type="fa" no-caps color="dark" class="btn-right" />-->
        <u-btn-dropdown
            color="grey-3" text-color="blue-grey-8"
            :popover-offset="[0, 4]"
        >
          <u-list class="list-default" link>
            <!--            <u-btn class="u-btn-list" v-close-overlay align="left" label="Relatório" flat no-caps />-->
            <div><u-btn @click="concluir" class="u-btn-list" v-close-overlay align="left" label="Concluir" flat no-caps/></div>
            <u-btn @click="excluir" class="u-btn-list" v-close-overlay align="left" label="Excluir" flat no-caps/>
            <!--            <u-btn class="u-btn-list" v-close-overlay align="left" label="Duplicar" flat no-caps />-->
          </u-list>
        </u-btn-dropdown>
      </div>
    </div>
  </div>
</template>

<script>
import CampanhaTitulo from "@/components/marketing/components/CampanhaTitulo"
import {UBtnDropdown, UList, CloseOverlay, UProgress} from 'uloc-vue'
import CampanhaHelper from "components/marketing/components/campanhaHelper"
import {deleteCampanha, upgradeCampanha} from "@/domain/marketing/services"

export default {
  name: "Campanha",
  props: ['campanha'],
  mixins: [CampanhaHelper],
  components: {
    CampanhaTitulo,
    UBtnDropdown,
    UList,
    UProgress
  },
  directives: {CloseOverlay},
  computed: {
    progressoCampanha () {
      const inscritos = Number(this.campanha.cacheInscritos)
      const enviados = Number(this.campanha.cacheEnviados)
      const total = ((enviados / inscritos) * 100).toFixed(2)
      return total > 100 ? 100 : total
    }
  },
  methods: {
    excluir() {
      this.confirmarExclusao('Tem certeza que deseja excluir esta campanha? Todos os dados serão perdidos, sem possibilidade de recuperá-los.', 'excluir campanha-' + this.campanha.id)
          .then(() => {
            this.$uloc.loading.show()
            deleteCampanha(this.campanha.id)
                .then(() => {
                  this.$uloc.loading.hide()
                  this.$uloc.notify({
                    color: 'positive',
                    message: `Campanha excluída com sucesso.`
                  })
                  this.$parent.load()
                })
                .catch(error => {
                  this.$uloc.loading.hide()
                  this.alertApiError(error)
                })
          })
          .catch(() => {
          })
    },
    concluir() {
      this.$uloc.loading.show()
      upgradeCampanha(this.campanha.id, {status: 100})
          .then(() => {
            this.$uloc.loading.hide()
            this.$uloc.notify({
              color: 'positive',
              message: `Campanha definida como concluída com sucesso.`
            })
            this.$parent.load()
          })
          .catch(error => {
            this.$uloc.loading.hide()
            this.alertApiError(error)
          })
    }
  }
}
</script>
