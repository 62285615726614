<script>
import ERow from "@/components/layout/components/Row"
import ECol from "@/components/layout/components/Col"
import {UBtnDropdown, UList, CloseOverlay} from 'uloc-vue'
import Campanha from "@/components/marketing/components/Campanha"
import {list} from '@/domain/marketing/services'
import SlLoading from "components/layout/components/Loading";
import ErpSField from "@/plugins/uloc-erp/components/form/SimpleField";

let filters = {
  status: 'ativas'
}

export default {
  name: 'Marketing',
  inject: {
    erplayout: {
      default() {
        console.error('Main needs to be child of ErpLayout')
      }
    }
  },
  directives: {CloseOverlay},
  components: {
    ErpSField,
    SlLoading,
    Campanha,
    ECol,
    ERow,
    UBtnDropdown, UList
    // Layout,
  },
  data() {
    return {
      limits: {},
      table: {
        busca: '',
        serverData: [],
        serverPagination: {
          sortBy: 'id',
          descending: true,
          page: 1,
          rowsNumber: 0, // specifying this determines pagination is server-side
          rowsPerPage: 50
        },
        filter: '',
        filters: JSON.parse(JSON.stringify(filters)),
        selected: [],
        loading: false
      }
    }
  },
  computed: {
    totalPages () {
      return Math.ceil(this.table.serverPagination.rowsNumber / this.table.serverPagination.rowsPerPage)
    }
  },
  mounted() {
    this.load()
    this.erplayout.erpheader.menu.setActiveMenu('marketing')
  },
  destroyed() {
    // document.querySelector('.u-erp-layout-container').classList.remove('bg-white')
  },
  methods: {
    load() {
      this.request({
        pagination: this.table.serverPagination,
        filter: this.table.busca
      })
    },
    request({pagination, filter}) {
      this.table.loading = true
      this.table.selected = []
      const extraFilters = []
      if (this.table.filters.status) {
        extraFilters.push('status=' + this.table.filters.status)
      }
      let filtros = encodeURI(`&sortBy=${pagination.sortBy}&descending=${pagination.descending}&search=${filter}&${extraFilters.join('&')}`)

      list(pagination.rowsPerPage, pagination.page, filtros)
          .then(({data}) => {
            this.table.serverPagination = pagination
            this.table.serverPagination.rowsNumber = data.total
            this.table.serverData = data.result || []
            this.limits = data.limits

            this.table.loading = false
            this.loadedData()
          })
          .catch(error => {
            this.loading = false
          })
    },
    changePage (page) {
      this.table.serverPagination.page = page
      this.$nextTick(() => {
        this.load()
      })
    },
    limparFiltros () {
      this.table.filters = JSON.parse(JSON.stringify(filters))
      this.$nextTick(() => {
        this.load()
      })
    }
  },
  meta: {
    title: 'Marketing',
    name: 'Marketing'
  }
}
</script>

<template>
  <transition name="slide" appear>
    <div class="transition-generic w-full wrapper-lg">
      <e-row>
        <e-col><h2 class="title">Comunicação e Marketing</h2></e-col>
        <e-col class="text-right">
          <div class="u-btn-group-container">
            <u-btn label="Criar Campanha" @click="$router.push({name: 'marketing.criar-campanha'})" no-caps color="dark"/>
            <!--              <u-btn icon="caret-down" icon-type="fa" no-caps color="dark" class="btn-right" />-->
            <u-btn-dropdown
                color="dark"
                :popover-offset="[0, 4]"
            >
              <u-list class="list-default flex column" link>
                <u-btn @click.native="$router.push({name: 'marketing.criar-campanha.canal', params: {canal: 'email', action: 'destinatarios'}})" class="u-btn-list" v-close-overlay align="left" label="Campanha de Email" icon="envelope"
                       icon-type="fa" icon-style="light" flat no-caps/>
                <u-btn disable class="u-btn-list" align="left" label="SMS" icon="mobile" icon-type="fa" icon-style="light"
                       flat no-caps/>
                <u-btn disable class="u-btn-list" align="left" label="WhatsApp" icon="whatsapp" icon-type="fa"
                       icon-style="brand" flat no-caps/>
                <u-btn disable class="u-btn-list" align="left" label="Voz" icon="phone" icon-type="fa" icon-style="light"
                       flat no-caps/>
              </u-list>
            </u-btn-dropdown>
          </div>

        </e-col>
      </e-row>

      <div class="wrapper bg-grey-4 b-radius-3px font-16">
        Limite de uso: <strong>{{limits.usedValue}}/{{limits.limit}}</strong> por hora. <span v-if="limits.expires && limits.expires.date" style="font-size: 10px">Renovação do limite: {{limits.expires|formatDateDistance}}</span>
      </div>

      <e-row class="m-t-md">
        <e-col style="display: none">
          <u-btn-dropdown
              color="grey-4"
              text-color="black"
              no-caps
              label="Filtros"
              :popover-offset="[0, 4]"

          >
            <u-list class="list-default" style="min-width: 280px;" link>
              <div class="wrapper-md">
                <e-row>
                  <e-col>Status</e-col>
                </e-row>

                <e-row class="m-t-xs">
                  <e-col>Canal</e-col>
                </e-row>
                <e-row>

                </e-row>
                <e-row>

                </e-row>
              </div>
            </u-list>
          </u-btn-dropdown>
        </e-col>
        <e-col>
          <e-row mr>
            <e-col>
              <erp-s-field label="Situação da campanha">
                <select v-model="table.filters.status" class="form-control">
                  <option :value="null">Tudo</option>
                  <option :value="'ativas'">Somente ativas</option>
                  <option :value="0">Rascunho</option>
                  <option :value="1">Em preparação</option>
                  <option :value="2">Programado</option>
                  <option :value="98">Pausado</option>
                  <option :value="3">Em andamento</option>
                  <option :value="100">Concluído</option>
                </select>
              </erp-s-field>
            </e-col>
            <e-col>
              <erp-s-field label="Canal">
              <select disabled class="form-control">
                <option>Tudo</option>
                <option>Email</option>
                <option>Voz</option>
                <option>SMS</option>
                <option>Whatsapp</option>
              </select>
              </erp-s-field>
            </e-col>
            <e-col style="display: flex; align-items: end">
              <u-btn @click="load" no-caps color="positive" label="Aplicar"/>
            </e-col>
            <e-col style="display: flex; align-items: end">
              <u-btn no-caps color="grey-3" text-color="black" @click="limparFiltros" label="Limpar filtros"/>
            </e-col>
          </e-row>
        </e-col>
        <e-col class="text-right">
          <div class="m-t">{{table.serverPagination.rowsNumber}} campanhas</div>
        </e-col>
      </e-row>

      <div v-if="table.loading" class="flex items-center m-t-lg m-b-lg">
        <sl-loading class="size-18 m-r" content="" /> Carregando campanhas
      </div>
      <div v-else-if="table.serverData && table.serverData.length">
      <campanha v-for="campanha in table.serverData" :key="campanha.id" :campanha="campanha" />
        <div class="m-t" v-if="table.serverPagination.rowsNumber > table.serverPagination.rowsPerPage">
          {{table.serverPagination.page}}-{{totalPages}} de {{table.serverPagination.rowsNumber}}
        </div>
        <div class="m-t">
          <a @click="changePage(page)" class="btn wrapper-xs m-r-sm" :class="{'bg-white': table.serverPagination.page !== page}" v-for="page in totalPages" :key="'page-' + page">{{page}}</a>
        </div>
      </div>
      <e-row v-else>
        <e-col class="m-t bg-white wrapper">
          Nenhuma campanha encontrada com os critérios de pesquisa.
        </e-col>
      </e-row>
    </div>
  </transition>
</template>

